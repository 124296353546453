import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from "src/app/shared/services/user";
import { AngularFirestore } from '@angular/fire/firestore';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ElementRef, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { MatTableDataSource, MatBottomSheet, MatHorizontalStepper } from '@angular/material';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material';
import { firestore } from 'firebase';
import { AngularFireStorage } from '@angular/fire/storage';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


//@ts-ignore

 

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css'],
  providers: []
})


export class SupportComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = ['Toyota'];
  allFruits: string[] = ['Toyota', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  dataSource: any;
  clientname: string;
  allclients: string[] = [];
  certCtrl = new FormControl();
  filteredCerts: Observable <string[]>;
  certs: string[] = ['DV360'];
  allcerts: string[] = [];

  message: string = 'Successfully added';
  actionButtonLabel: string = 'Done';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2500;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  
  addExtraClass: boolean = false;
  

  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
  @ViewChild(MatHorizontalStepper, {static: true}) stepper: MatHorizontalStepper;
  getdata: any;
  user: any;
  userdoc: any;
  fruitlist: Promise<void>;
  clientnames: any;
  myclients: string[];
  certInput: any;
  valuecertstatus: any;
  cert: any;
  certarray: { certificatename: string | any; start: Date | any; end: Date | any; champion: string | any; };
  valuecertstart: any;
  valuecertend: any;
  certarraylist: any | null | undefined;
  certselections: any[];
  newcertificates: string[];
  rep: string;
  allcertificationsofuser: any[] | null | undefined;
  usuariosRef: any;
  query: any;
  certifications: any;
  certificaciones: any;
  records: any;
  moredetailslist: any[];
  moredetails: any[] | string[] | null | undefined;
  moreclientdetails: any | null | undefined;
  moreclientdetailslist: any;
  nondetails: { FirstName: string; LastName: string; Birthday: Date; EntryDate: Date; ExitDate: Date; }[];
  championstatus: string;
  valuechampionstatus: string;
  urlimage: Observable<any>;
  

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;
  seventhFormGroup: FormGroup;
  valuephone: any | null | undefined | string | number;
  valueemail: any | null | undefined| string;
  valueagencycontact: any | null | undefined | string | number;
  valueagency: any | null | undefined | string;
  valueadvname: any | null | undefined | string;
  rowdetailsetapa0: { AdvertiserName: any | null | undefined; Agency: any | null | undefined; AgencyContact: any | null | undefined; Email: any | null | undefined; Phone: any | null | undefined; };
  agency: any | null | undefined | string;
  selectedpricingmodel: any | null | undefined | string;
  selectedpricingmodelcomment: any | null | undefined | string;
  rowdetailsetapa1: { PrecisionPricingModel: any[]; PrecisionRate: any[]; BrandSafetyCategories: any[]; SuspiciousActivityPlatform: any[]; FrequencyCap: any[]; };
  selectedrate: any | null | undefined | string;
  selectedratecomment: any | null | undefined | string;
  selectedbrandsafety: any | null | undefined | string;
  selectedbrandsafetycomment: any | null | undefined | string;
  selectedfraudactivity: any | null | undefined | string;
  selectedfraudactivitycomment: any | null | undefined | string;
  selectedfrequency: any | null | undefined | string;
  selectedfrequencycomment: any | null | undefined | string;
  model: any | null | undefined | string;
  cat: any | null | undefined | string;
  cat1: any | null | undefined | string;
  selecteddayorhour: any;
  selecteddayorhourcomment: string;
  selectedgeo: any;
  selectedgeocomment: any;
  selectedaudience: string;
  selectedaudiencecomment: string;
  selectedother: any;
  selectedothercomment: any;
  rowdetailsetapa2: { DayOrHour: any[]; Geo: any[]; AudienceCategories: string[]; Other: any[]; };
  selectedinventory: any;
  selectedinventorycomment: any;
  selectedblocked: any;
  selectedblockedcomment: any;
  selectedlists: any;
  selectedlistscomment: any;
  rowdetailsetapa3: { InventoryRestrictions: any[]; BlockedVendorsOrExchanges: any[]; WhitelistsOrBlacklists: any[]; };
  selectedprimarykpi: any;
  selectedprimarykpicomment: any;
  kpi: any;
  kpi1: any;
  selectedsecondarykpi: any;
  selectedsecondarykpicomment: any;
  selectedotherkpicomment: any;
  selectedviewability: any;
  selectedfraud: any;
  selectedviewabilitycomment: any;
  selectedfraudcomment: any;
  rowdetailsetapa4: { PrimaryKPI: any[]; SecondaryKPI: any[]; Viewability: any[]; Fraud: any[]; OtherKPI: any[]; };
  selectedotherkpi: any;
  selecteddmp: any;
  selecteddmpcomment: any;
  dmp: any;
  selectedver: any;
  catnew: any;
  selectedvercomment: any;
  selectedservercomment: any;
  selectedserver: any;
  server: any;
  selectedlocationvisit: any;
  selectedlocationvisitcomment: any;
  selectedrichmediacomment: any;
  selectedrichmedia: any;
  selectedcreativecomment: any;
  selectedcreative: any;
  selectedcomscorecomment: any;
  selectedcomscore: any;
  rowdetailsetapa5: { DMP: any[]; AdServer: any[]; Verification: any[]; LocationVisit: any[]; RichMedia: any[]; DynamicCreativePartner: any[]; ComscoreVCE: any[]; };
  selecteddspnew: any;
  selecteddspnewcomment: any;
  dspnew: any | any[] | null | undefined | string[] | string;
  rowdetailsetapa6: { DSPdetails: any[]; DSPs: any[]};
  selectedfolder: any;
  selectedfoldercomment: any;
  selecteddelivery: any;
  selecteddeliverycomment: any;
  selectedaccess: any;
  selectedaccesscomment: any;
  rowdetailsetapa7: { LionFolder: any[]; TeamAccess: any[]; Delivery: any[]; };
  myjson: string;
  task: any;
  


  constructor(@Inject(Router) private router: Router, private _formBuilder: FormBuilder, public dialog: MatDialog , private afs: AngularFirestore, public snackBar: MatSnackBar, private bottomSheet: MatBottomSheet, private storage: AngularFireStorage) { }

  ngOnInit() {
    this.router.navigate(['/support']);
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    this.fourthFormGroup = this._formBuilder.group({
      fourthCtrl: ['', Validators.required]
    });
    this.fifthFormGroup = this._formBuilder.group({
      fifthCtrl: ['', Validators.required]
    });
    this.sixthFormGroup = this._formBuilder.group({
      sixthCtrl: ['', Validators.required]
    });
    this.seventhFormGroup = this._formBuilder.group({
      seventhCtrl: ['', Validators.required]
    });
    this.processinitialcerts();
}

complete() {
  this.stepper.selected.completed = true;
  this.stepper.previous()
}

newId = this.afs.createId();

addetapa1() {
  if (this.valueadvname || this.valueagencycontact || this.agency || this.valueemail || this.valuephone || this.selectedpricingmodel || this.selectedrate || this.selectedbrandsafety || this.selectedfraudactivity || this.selectedfrequency == undefined) {
    this.stepper.previous();
  }
  if (this.valueadvname == undefined) {
    document.getElementById('adv').style.color = 'red';
  }
  if (this.agency == undefined) {
    document.getElementById('exampleFormControlSelect1').style.color = 'red';
  }
  if (this.valueagencycontact == undefined) {
    document.getElementById('agency').style.color = 'red';
  }
  if (this.valueemail == undefined) {
    document.getElementById('email').style.color = 'red';
  }
  if (this.valuephone == undefined) {
    document.getElementById('phone').style.color = 'red';
  }
  if (this.selectedpricingmodel == undefined) {
    document.getElementById('radiouno').style.color = 'red';
  }
  if (this.selectedrate == undefined) {
    document.getElementById('radiodos').style.color ='red';
  }
  if (this.selectedbrandsafety == undefined) {
    document.getElementById('radiotres').style.color ='red';
  }
  if (this.selectedfraudactivity == undefined) {
    document.getElementById('radioquatro').style.color ='red';
  }
  if (this.selectedfrequency == undefined) {
    document.getElementById('radiocinco').style.color ='red';
  }
  if (this.model == undefined) {
    this.model = 'None'
  }

  if (this.selectedpricingmodelcomment == undefined) {
    this.selectedpricingmodelcomment = 'None'
  }
  
  if (this.selectedratecomment == undefined) {
    this.selectedratecomment = 'None'
  }
  if(this.cat == undefined) {
    this.cat = 'None'
  }
  if (this.cat1 == undefined) {
    this.cat1 = 'None'
  }
  if (this.selectedbrandsafetycomment == undefined) {
    this.selectedbrandsafetycomment = 'None'
  }
  if (this.selectedfraudactivitycomment == undefined) {
    this.selectedfraudactivitycomment = 'None'
  }
  if (this.selectedfrequencycomment == undefined) {
    this.selectedfrequencycomment = 'None'
  }
  if (this.valueadvname && this.agency && this.valueagencycontact && this.valueemail && this.valuephone && this.selectedpricingmodel && this.selectedrate && this.selectedbrandsafety && this.selectedfraudactivity && this.selectedfrequency != undefined) {
    this.stepper.next();
    this.rowdetailsetapa0 = {AdvertiserName: this.valueadvname, Agency: this.agency, AgencyContact: this.valueagencycontact, Email: this.valueemail, Phone: this.valuephone};
    console.log(this.rowdetailsetapa0);
    this.afs.collection('newclients').doc(this.newId).set({ClientDetails: this.rowdetailsetapa0});
    console.log(this.selectedpricingmodel, this.model,this.selectedpricingmodelcomment, this.selectedrate, this.selectedratecomment, this.cat, this.selectedbrandsafety, this.selectedbrandsafetycomment, this.cat1, this.selectedfraudactivity, this.selectedfraudactivitycomment, this.selectedfrequency, this.selectedfrequencycomment);
    this.rowdetailsetapa1 = {PrecisionPricingModel: [this.selectedpricingmodel, this.model,this.selectedpricingmodelcomment], PrecisionRate: [this.selectedrate, this.selectedratecomment], BrandSafetyCategories: [this.selectedbrandsafety, this.cat, this.selectedbrandsafetycomment], SuspiciousActivityPlatform: [this.selectedfraudactivity, this.cat1, this.selectedfraudactivitycomment], FrequencyCap: [this.selectedfrequency, this.selectedfrequencycomment]};
    document.getElementById('radiouno').style.color = 'grey';
    document.getElementById('radiodos').style.color = 'grey';
    document.getElementById('radiotres').style.color = 'grey';
    document.getElementById('radioquatro').style.color = 'grey';
    document.getElementById('radiocinco').style.color = 'grey';
    document.getElementById('adv').style.color = 'grey';
    document.getElementById('exampleFormControlSelect1').style.color = 'grey';
    document.getElementById('agency').style.color = 'grey';
    document.getElementById('email').style.color = 'grey';
    document.getElementById('phone').style.color = 'grey';
  }
  this.afs.collection('newclients').doc(this.newId).update({AdvertiserGuidelines: this.rowdetailsetapa1});
  console.log(this.rowdetailsetapa1);
}

addetapa2() {

  if (this.selecteddayorhour || this.selectedgeo || this.selectedaudience || this.selectedother == undefined) {
    this.stepper.previous();
  }
  if (this.selecteddayorhour == undefined) {
    document.getElementById('radioseis').style.color = 'red';
  }
  if (this.selecteddayorhourcomment == undefined) {
    this.selecteddayorhourcomment = 'None'
  }
  if (this.selectedgeo == undefined) {
    document.getElementById('radiosiete').style.color = 'red';
  }
  if (this.selectedgeocomment == undefined) {
    this.selectedgeocomment = 'None'
  }
  if (this.selectedaudience == undefined) {
    document.getElementById('radioocho').style.color = 'red';
  }
  if (this.selectedaudiencecomment == undefined) {
    this.selectedaudiencecomment = 'None'
  }
  if (this.selectedother == undefined) {
    document.getElementById('radionuevo').style.color = 'red';
  }
  if (this.selectedothercomment == undefined) {
    this.selectedothercomment = 'None'
  }
  if (this.selecteddayorhour && this.selectedgeo && this.selectedaudience && this.selectedother != undefined) {
    this.rowdetailsetapa2 = {DayOrHour: [this.selecteddayorhour, this.selecteddayorhourcomment], Geo: [this.selectedgeo, this.selectedgeocomment], AudienceCategories: [this.selectedaudience, this.selectedaudiencecomment], Other: [this.selectedother, this.selectedothercomment]};
    this.stepper.next();
    this.afs.collection('newclients').doc(this.newId).update({TargetingRestrictions: this.rowdetailsetapa2});
    console.log(this.rowdetailsetapa2);
    document.getElementById('radioseis').style.color = 'grey';
    document.getElementById('radiosiete').style.color = 'grey';
    document.getElementById('radioocho').style.color = 'grey';
    document.getElementById('radionuevo').style.color = 'grey';

  }

}

addetapa3() {

  if (this.selectedinventory || this.selectedblocked || this.selectedlists == undefined) {
    this.stepper.previous();
  }
  if (this.selectedinventory == undefined) {
    document.getElementById('radiodiez').style.color = 'red';
  }
  if (this.selectedinventorycomment == undefined) {
    this.selectedinventorycomment = 'None'
  }
  if (this.selectedblocked == undefined) {
    document.getElementById('radioonce').style.color = 'red';
  }
  if (this.selectedblockedcomment == undefined) {
    this.selectedblockedcomment = 'None'
  }
  if (this.selectedlists == undefined) {
    document.getElementById('radiodoce').style.color = 'red';
  }
  if (this.selectedlistscomment == undefined) {
    this.selectedlistscomment = 'None'
  }
  if (this.selectedinventory && this.selectedblocked && this.selectedlists != undefined) {
    this.rowdetailsetapa3 = {InventoryRestrictions: [this.selectedinventory, this.selectedinventorycomment], BlockedVendorsOrExchanges: [this.selectedblocked, this.selectedblockedcomment], WhitelistsOrBlacklists: [this.selectedlists, this.selectedlistscomment]};
    this.stepper.next();
    console.log(this.rowdetailsetapa3);
    this.afs.collection('newclients').doc(this.newId).update({InventoryRestrictions: this.rowdetailsetapa3});
    document.getElementById('radioonce').style.color = 'grey';
    document.getElementById('radiodiez').style.color = 'grey';
    document.getElementById('radiodoce').style.color = 'grey';

  }
  
}

addetapa4() {

  if (this.selectedprimarykpi || this.selectedsecondarykpi || this.selectedviewability || this.selectedfraud || this.selectedotherkpi == undefined) {
    this.stepper.previous();
  }
  if (this.selectedprimarykpi == undefined) {
    document.getElementById('radiotrece').style.color = 'red';
  }
  if (this.selectedprimarykpicomment == undefined) {
    this.selectedprimarykpicomment = 'None'
  }
  if (this.kpi == undefined) {
    this.kpi = 'None'
  }
  if (this.kpi1 == undefined) {
    this.kpi1 = 'None'
  }
  if (this.selectedsecondarykpi == undefined) {
    document.getElementById('radioquatorce').style.color = 'red';
  }
  if (this.selectedsecondarykpicomment == undefined) {
    this.selectedsecondarykpicomment = 'None'
  }
  if (this.selectedotherkpicomment == undefined) {
    this.selectedotherkpicomment = 'None'
  }
  if (this.selectedotherkpi == undefined) {
    document.getElementById('radiodiecisiete').style.color = 'red';
  }
  if (this.selectedviewability == undefined) {
    document.getElementById('radioquince').style.color = 'red';
  }
  if (this.selectedfraud == undefined) {
    document.getElementById('radiodieciseis').style.color = 'red';
  }
  if (this.selectedviewabilitycomment == undefined) {
    this.selectedviewabilitycomment = 'None'
  }
  if (this.selectedfraudcomment == undefined) {
    this.selectedfraudcomment = 'None'
  }
  if (this.selectedprimarykpi && this.selectedsecondarykpi && this.selectedviewability && this.selectedfraud && this.selectedotherkpi != undefined) {
    this.rowdetailsetapa4 = {PrimaryKPI: [this.selectedprimarykpi, this.kpi, this.selectedprimarykpicomment], SecondaryKPI: [this.selectedsecondarykpi, this.kpi1, this.selectedsecondarykpicomment], Viewability: [this.selectedviewability, this.selectedviewabilitycomment], Fraud: [this.selectedfraud, this.selectedfraudcomment], OtherKPI: [this.selectedotherkpi, this.selectedotherkpicomment]};
    console.log(this.rowdetailsetapa4);
    this.stepper.next();
    this.afs.collection('newclients').doc(this.newId).update({PerformanceKPIs: this.rowdetailsetapa4});
    document.getElementById('radiotrece').style.color = 'grey';
    document.getElementById('radioquatorce').style.color = 'grey';
    document.getElementById('radioquince').style.color = 'grey';
    document.getElementById('radiodieciseis').style.color = 'grey';
    document.getElementById('radiodiecisiete').style.color = 'grey';
  }
  
}

addetapa5() {

  if (this.selecteddmp || this.selectedver || this.selectedserver || this.selectedlocationvisit || this.selectedrichmedia || this.selectedcreative || this.selectedcomscore == undefined) {
    this.stepper.previous();
  }
  if (this.selecteddmp == undefined) {
    document.getElementById('radiodieciocho').style.color = 'red';
  }
  if (this.selecteddmpcomment == undefined) {
    this.selecteddmpcomment = 'None'
  }
  if (this.dmp == undefined) {
    this.dmp = 'None'
  }
  if (this.selectedver == undefined) {
    document.getElementById('radiovente').style.color = 'red';
  }
  if (this.catnew == undefined) {
    this.catnew = 'None'
  }
  if (this.selectedvercomment == undefined) {
    this.selectedvercomment = 'None'
  }
  if (this.selectedservercomment == undefined) {
    this.selectedservercomment = 'None'
  }
  if (this.selectedserver == undefined) {
    document.getElementById('radiodiecinueve').style.color = 'red';
  }
  if (this.server == undefined) {
    this.server = 'None'
  }
  if (this.selectedlocationvisit == undefined) {
    document.getElementById('radioventeuno').style.color = 'red';
  }
  if (this.selectedlocationvisitcomment == undefined) {
    this.selectedlocationvisitcomment = 'None'
  }
  if (this.selectedrichmediacomment == undefined) {
    this.selectedrichmediacomment = 'None'
  }
  if (this.selectedrichmedia == undefined) {
    document.getElementById('radioventedos').style.color = 'red';
  }
  if (this.selectedcreativecomment == undefined) {
    this.selectedcreativecomment = 'None'
  }
  if (this.selectedcreative == undefined) {
    document.getElementById('radioventetres').style.color = 'red';
  }
  if (this.selectedrichmediacomment == undefined) {
    this.selectedrichmediacomment = 'None'
  }
  if (this.selectedcomscorecomment == undefined) {
    this.selectedcomscorecomment = 'None'
  }
  if (this.selectedcomscore == undefined) {
    document.getElementById('radioventequatro').style.color = 'red';
  }
  if (this.selecteddmp && this.selectedserver && this.selectedver && this.selectedlocationvisit && this.selectedrichmedia && this.selectedcreative && this.selectedcomscore != undefined) {
    this.rowdetailsetapa5 = {DMP: [this.selecteddmp, this.dmp, this.selecteddmpcomment], AdServer: [this.selectedserver, this.server, this.selectedservercomment], Verification: [this.selectedver, this.catnew, this.selectedvercomment], LocationVisit: [this.selectedlocationvisit, this.selectedlocationvisitcomment], RichMedia: [this.selectedrichmedia, this.selectedrichmediacomment], DynamicCreativePartner: [this.selectedcreative, this.selectedcreativecomment], ComscoreVCE: [this.selectedcomscore, this.selectedcomscorecomment]};
    console.log(this.rowdetailsetapa5);
    this.afs.collection('newclients').doc(this.newId).update({ThirdPartyTechIntegrations: this.rowdetailsetapa5});
    this.stepper.next();
    document.getElementById('radiodieciocho').style.color = 'grey';
    document.getElementById('radiodiecinueve').style.color = 'grey';
    document.getElementById('radiovente').style.color = 'grey';
    document.getElementById('radioventeuno').style.color = 'grey';
    document.getElementById('radioventedos').style.color = 'grey';
    document.getElementById('radioventetres').style.color = 'grey';
    document.getElementById('radioventequatro').style.color = 'grey';
  }
  
}

addetapa6() {
  console.log(this.dspnew);
  if (this.selecteddspnew == undefined) {
    document.getElementById('radioventecinco').style.color = 'red';
    this.stepper.previous();
  }
  if (this.selecteddspnewcomment == undefined) {
    this.selecteddspnewcomment = 'None'
  }
  if (this.dspnew == undefined) {
    this.dspnew = 'None'
  }
  if (this.selecteddspnew != undefined) {
    this.rowdetailsetapa6 = {DSPdetails: [this.selecteddspnew, this.selecteddspnewcomment], DSPs: this.dspnew};
    console.log(this.rowdetailsetapa6);
    this.afs.collection('newclients').doc(this.newId).update({DSPs: this.rowdetailsetapa6});
    document.getElementById('radioventecinco').style.color = 'grey';
    this.stepper.selectedIndex = 6;
  }
}

addetapa7() {
  if (this.selectedfolder || this.selectedaccess || this.selecteddelivery == undefined) {
    this.stepper.previous();
  }
  if (this.selectedfolder == undefined) {
    document.getElementById('radioventeseis').style.color = 'red';
  }
  if (this.selectedfoldercomment == undefined) {
    this.selectedfoldercomment = 'None'
  }
  if (this.selecteddelivery == undefined) {
    document.getElementById('radioventeocho').style.color = 'red';

  }
  if (this.selecteddeliverycomment == undefined) {
    this.selecteddeliverycomment = 'None'
  }
  if (this.selectedaccess == undefined) {
    document.getElementById('radioventesiete').style.color = 'red';

  }
  if (this.selectedaccesscomment == undefined) {
    this.selectedaccesscomment = 'None'
  }
  if (this.selectedfolder && this.selectedaccess && this.selecteddelivery != undefined) {
    this.rowdetailsetapa7 = {LionFolder: [this.selectedfolder, this.selectedfoldercomment], TeamAccess: [this.selectedaccess, this.selectedaccesscomment], Delivery: [this.selecteddelivery, this.selecteddeliverycomment]};
    console.log(this.rowdetailsetapa7)
    this.afs.collection('newclients').doc(this.newId).update({MaterialsDeliverables: this.rowdetailsetapa7});
    this.stepper.next();
    document.getElementById('radioventeseis').style.color = 'grey';
    document.getElementById('radioventesiete').style.color = 'grey';
    document.getElementById('radioventeocho').style.color = 'grey';
  }
}

clearreset() {
  this.valueadvname = '';
  this.agency = '';
  this.valueagencycontact = '';
  this.valueemail = '';
  this.valuephone = '';
  this.selectedpricingmodel = '';
  this.selectedpricingmodelcomment = '';
  this.model = '';
  this.selectedrate = '';
  this.selectedratecomment = '';
  this.selectedfrequency = '';
  this.selectedfrequencycomment = '';
  this.selectedbrandsafety = '';
  this.selectedbrandsafetycomment = '';
  this.selectedfraudactivity = '';
  this.selectedfraudactivitycomment = '';
  this.selecteddayorhour = '';
  this.selecteddayorhourcomment = '';
  this.selectedgeo = '';
  this.selectedgeocomment = '';
  this.selectedaudience = '';
  this.selectedaudiencecomment = '';
  this.selectedfraud = '';
  this.selectedfraudcomment = '';
  this.cat = '';
  this.cat1 = '';
  this.catnew = '';
  this.kpi = '';
  this.dmp = '';
  this.dspnew = '';
  this.server = '';
  this.selectedother = '';
  this.selectedothercomment = '';
  this.selectedotherkpi = '';
  this.selectedotherkpicomment = '';
  this.selectedinventory = '';
  this.selectedinventorycomment = '';
  this.selectedblocked = '';
  this.selectedblockedcomment = '';
  this.selectedviewability = '';
  this.selectedviewabilitycomment = '';
  this.selectedlists = '';
  this.selectedlistscomment = '';
  this.selectedprimarykpi = '';
  this.selectedprimarykpicomment = '';
  this.selectedsecondarykpi = '';
  this.selectedsecondarykpicomment = '';
  this.kpi1 = '';
  this.selectedver = '';
  this.selectedvercomment = '';
  this.selecteddmp = '';
  this.selecteddmpcomment = '';
  this.selectedserver = '';
  this.selectedservercomment = '';
  this.selectedlocationvisit = '';
  this.selectedlocationvisitcomment = '';
  this.selectedaccess = '';
  this.selectedaccesscomment = '';
  this.selectedrichmedia = '';
  this.selectedrichmediacomment = '';
  this.selectedcreative = '';
  this.selectedcreativecomment = '';
  this.selectedcomscore = '';
  this.selectedcomscorecomment = '';
  this.selecteddspnew = '';
  this.selecteddspnewcomment = '';
  this.selectedfolder = '';
  this.selectedfoldercomment = '';
  this.selecteddelivery = '';
  this.selecteddeliverycomment = '';
}


show1(){
  document.getElementById('div1').style.display ='none';
}
show2(){
  document.getElementById('div1').style.display = 'block';
}
show3(){
  document.getElementById('div2').style.display ='none';
}
show4(){
  document.getElementById('div2').style.display = 'block';
}
show5(){
  document.getElementById('div3').style.display ='none';
}
show6(){
  document.getElementById('div3').style.display = 'block';
}
show7(){
  document.getElementById('div4').style.display ='none';
}
show8(){
  document.getElementById('div4').style.display = 'block';
}
show9(){
  document.getElementById('div5').style.display ='none';
}
show10(){
  document.getElementById('div5').style.display = 'block';
}
show11(){
  document.getElementById('div6').style.display ='none';
}
show12(){
  document.getElementById('div6').style.display = 'block';
}
show13(){
  document.getElementById('div7').style.display ='none';
}
show14(){
  document.getElementById('div7').style.display = 'block';
}
show15(){
  document.getElementById('div8').style.display ='none';
}
show16(){
  document.getElementById('div8').style.display = 'block';
}
show17(){
  document.getElementById('div9').style.display ='none';
}
show18(){
  document.getElementById('div9').style.display = 'block';
}


agencies = ['Spark', 'Digitas', 'Zenith', 'Blue449', 'Saatchi and Saatchi', 'RUN', 'PHM']
services = ['Programmatic', 'Social', 'Search', 'Other']
platforms = ['TTD', 'DV360', 'Amobee', 'Amazon', 'Verizon', 'Adelphic', 'Adobe', 'Media Math', 'Vistar', 'Other']
categories = ['IAS', 'Double Verify', 'MOAT', 'Other']
models = ['% Total Cost', '% Total Media Cost', '% Total Data Cost', 'Other']
kpis = ['CPM', 'CPC', 'CTR', 'CPA', 'Completion Rate', 'Viewability', 'ROAS', 'CPCV', 'Other']
dmps = ['Lotame', 'Bluekai', 'Oracle', 'Datalogix', 'Other']
servers = ['Campaign Manager', 'Sizmek', 'OpenX', 'Other']


alternate: boolean = true;
  toggle: boolean = true;
  color: boolean = false;
  size: number = 40;
  expandEnabled: boolean = true;
  side = 'left';



opensnack() {
  let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

}


processinitialcerts() {
  this.afs.collection('newclients').valueChanges().subscribe((results) => {
    this.processcertificates(results);;
});

}

async processcertificates(records:any) {
  this.myjson = JSON.stringify(records);
}

mailing() {
  const name = 'reportingjson';
  const path = `clientonboarding/${new Date().getTime()}_${name}`;
  var blob = new Blob([this.myjson], {type: "application/json"})
  this.task = this.storage.upload(path, blob);
  
}

timefunction() {
  window.setTimeout(function(){window.location.reload()}, 12000);
}


}

