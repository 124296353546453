export const environ = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyASI-rbgKKSNYQJkeUgnUqXh8jpwamjxjs",
    authDomain: "trialbasicprecision.firebaseapp.com",
    databaseURL: "https://trialbasicprecision.firebaseio.com",
    projectId: "trialbasicprecision",
    storageBucket: "trialbasicprecision.appspot.com",
    messagingSenderId: "1039931456365",
    appId: "1:1039931456365:web:5abd4396bc3350dbb13864",
    measurementId: "G-1Q5LZ6BQPV",
  }
};
