import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { SupportComponent } from  './support/support.component';




const routes: Routes = [ { path: 'support', component: SupportComponent },
  { path: '', redirectTo: 'support', pathMatch: 'full' }, { path: '**', redirectTo: '', pathMatch: 'full'}];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,{ enableTracing: true } // <-- debugging purposes only
    )
    // other imports here
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
export const routingcomponents = [SupportComponent]
