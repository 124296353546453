import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule, routingcomponents } from './app-routing.module';
import { Router, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { SupportModule } from  './support/support.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule, MatChipsModule, MatDialogModule, MatBottomSheetModule, MatTooltipModule, MatBottomSheet, MatBadgeModule, MatProgressBarModule, MatIconModule, MatSidenavModule, MatAccordion, MatTableModule, MatExpansionModule, MatHorizontalStepper, MatInputModule, MatListModule, MatButtonModule, MatPaginatorModule, MatSortModule, MatSelectModule, MatSort, MatPaginator, MatStepper, MatAutocomplete, MatTooltip} from  '@angular/material';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';
import { environ } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { NgbDateFirestoreAdapter } from 'src/app/ngb-date-firestore-adapter.service';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSnackBarModule, MatSnackBar} from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTreeModule} from '@angular/material/tree';


// Import canActivate guard services
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireStorageModule, AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { DropZoneDirective } from './drop-zone.directive';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';







@NgModule({
  declarations: [
      AppComponent,
      routingcomponents,
      DropZoneDirective
  ],
  entryComponents: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    NgbModule,
    MatSnackBarModule,
    AngularFireStorageModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    CommonModule,
    MatTooltipModule,
    MatDialogModule,
    MatChipsModule,
    RouterModule,
    MatBadgeModule,
    MatGridListModule,
    MatProgressBarModule,
    NgxChartsModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatStepperModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatToolbarModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatListModule,
    MatSelectModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule,
    FormsModule,
    AngularFireAuthModule,
    MatIconModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    AngularFireStorageModule,
    NgbModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatChipsModule,
    AngularFireModule.initializeApp(environ.firebaseConfig),
    AngularFirestoreModule.enablePersistence()
  ],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateFirestoreAdapter }, MatBottomSheet, MatTooltip, MatAutocomplete, HttpClient, MatSort, MatSnackBar, AngularFireAuth, DatePipe, MatPaginator, MatStepper, MatAccordion, MatHorizontalStepper, MatDialog, {provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}}],
  bootstrap: [AppComponent]
})
export class AppModule {
}