import { Component, ViewChild, OnInit, Inject, NO_ERRORS_SCHEMA } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DataSource } from '@angular/cdk/collections';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { database, constructor } from 'firebase';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs/Rx';
import { MatAccordion } from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Chart } from 'chart.js';
import { Router, RouterModule, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

//var CanvasJS = require('src/assets/canvasjs/dist/canvasjs.min.js');



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit {

  
  title = 'Advertiser CV Onboarding';
  

  

  constructor( private afs: AngularFirestore, public dialog: MatDialog, private router: Router, private http: HttpClient) {

  }


  ngOnInit() {}

    

  onSelect(event: any) {
    console.log(event);
  };


}
